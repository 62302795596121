import React from "react";
import { motion } from "framer-motion";
import { MapPin, Phone, Mail, Facebook, Instagram } from "lucide-react";

const Footer = () => {
  return (
    <footer className="bg-gray-800 border-t border-gray-700">
      <div className="max-w-7xl mx-auto px-8 py-16">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-12"
        >
          {/* Logo & Description */}
          <div className="lg:col-span-1">
            <h3 className="text-xl font-medium text-white mb-4">
              Buddima Samudra
            </h3>
            <p className="text-gray-400 mb-6">
              Experience luxury and comfort in the heart of Nikaweratiya.
            </p>
          </div>

          {/* Contact Info */}
          <div className="lg:col-span-2">
            <h4 className="text-sm font-medium text-white uppercase tracking-wider mb-4">
              Contact Information
            </h4>
            <div className="space-y-4">
              <div className="flex items-start space-x-3">
                <MapPin className="w-5 h-5 text-gray-400 mt-1 flex-shrink-0" />
                <p className="text-gray-400">
                  Kurunegala Road, Nikaweratiya, Sri Lanka, 60470
                </p>
              </div>
              <div className="flex items-center space-x-3">
                <Phone className="w-5 h-5 text-gray-400 flex-shrink-0" />
                <a
                  href="tel:0372260256"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  0372 260 256
                </a>
              </div>
              <div className="flex items-center space-x-3">
                <Mail className="w-5 h-5 text-gray-400 flex-shrink-0" />
                <a
                  href="mailto:buddisamudrahotel@gmail.com"
                  className="text-gray-400 hover:text-white transition-colors"
                >
                  buddisamudrahotel@gmail.com
                </a>
              </div>
            </div>
          </div>

          {/* Social Links */}
          <div>
            <h4 className="text-sm font-medium text-white uppercase tracking-wider mb-4">
              Follow Us
            </h4>
            <div className="flex space-x-4">
              <a
                href="https://facebook.com/buddimasamudra"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <Facebook className="w-6 h-6" />
              </a>
              <a
                href="https://www.instagram.com/buddimasamudra/"
                target="_blank"
                rel="noopener noreferrer"
                className="text-gray-400 hover:text-white transition-colors"
              >
                <Instagram className="w-6 h-6" />
              </a>
            </div>
          </div>
        </motion.div>

        {/* Copyright */}
        <div className="border-t border-gray-700 mt-12 pt-8">
          <p className="text-center text-gray-500 text-sm">
            © {new Date().getFullYear()} Buddima Samudra. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
