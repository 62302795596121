import React from "react";
import { motion } from "framer-motion";
import hotelbackground from "../assets/hotel-exterior.jpg";
const AboutSection = () => {
  return (
    <section className="py-24 px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.6 }}
          className="grid grid-cols-1 md:grid-cols-2 gap-12 items-center"
        >
          {/* Image Container */}
          <motion.div
            initial={{ opacity: 0, x: -20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6 }}
            className="relative h-[500px] w-full overflow-hidden rounded-lg"
          >
            <img
              src={hotelbackground}
              alt="Luxury Hotel View"
              className="object-cover w-full h-full"
            />
          </motion.div>

          {/* Content Container */}
          <motion.div
            initial={{ opacity: 0, x: 20 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            transition={{ duration: 0.6, delay: 0.2 }}
            className="space-y-6"
          >
            <h3 className="text-sm font-medium text-gray-500 uppercase tracking-wider">
              Welcome to
            </h3>
            <h2 className="text-3xl font-medium text-gray-900">
              Hotel Buddima Samudra
            </h2>
            <p className="text-gray-600 leading-relaxed">
              Nestled along the serene shores of Magalla Lake in Nikaweratiya,
              Sri Lanka, Hotel Buddi Samudra offers a tranquil retreat for
              travelers seeking both comfort and natural beauty. Our 24
              well-appointed rooms are designed to provide a restful experience,
              featuring modern amenities such as air conditioning, LED
              televisions with satellite channels, complimentary Wi-Fi, and cozy
              fireplaces.
            </p>
            <p className="text-gray-600 leading-relaxed">
              Guests can indulge in a variety of culinary delights at our
              on-site restaurant, which serves a delectable array of local and
              international cuisines. Our recreational facilities include an
              outdoor swimming pool, fitness center, and children's playground.
              Conveniently located near attractions like Padeniya Raja Maha
              Viharaya and Panduwasnuwara Museum, we serve as an ideal base for
              exploring the region's rich cultural heritage.
            </p>
            <motion.div
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.4 }}
            >
              <motion.button
                whileHover={{ scale: 1.02 }}
                whileTap={{ scale: 0.98 }}
                className="mt-8 px-6 py-3 border border-black text-black bg-transparent rounded-md hover:bg-black hover:text-white transition-colors"
              >
                About Us
              </motion.button>
            </motion.div>
          </motion.div>
        </motion.div>
      </div>
    </section>
  );
};

export default AboutSection;
