import React from "react";
import { motion } from "framer-motion";

// Reusing the same image for all sections
import hotelImage from "../assets/hotel.jpg";

const AboutUs = () => {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="bg-white"
    >
      {/* Simple Header */}
      <div className="bg-gray-50 py-16 px-4 text-center">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          About Hotel Buddi Samudra
        </h1>
        <p className="text-gray-600">
          Where Serenity Meets Modern Comfort
        </p>
      </div>

      {/* Basic Overview */}
      <section className="py-12 px-4 max-w-4xl mx-auto">
        <div className="mb-8">
          <img
            src={hotelImage}
            alt="Hotel exterior"
            className="rounded-lg shadow-sm"
          />
        </div>
        <div className="prose text-gray-600">
          <p>
            Established in 2012, Hotel Buddi Samudra sits gracefully on the edge 
            of Magalla Reservoir near Nikaweratiya. Our 19 well-appointed rooms 
            blend modern comforts with the tranquil beauty of our natural 
            surroundings, located just 40 minutes from Kurunegala and 120km 
            from Colombo.
          </p>
        </div>
      </section>

      {/* Key Facts */}
      <section className="bg-gray-50 py-12 px-4">
        <div className="max-w-4xl mx-auto grid md:grid-cols-2 gap-8">
          <div>
            <h2 className="text-xl font-semibold mb-4">Accommodation</h2>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>19 modern rooms</li>
              <li>Free Wi-Fi access</li>
              <li>Outdoor swimming pool</li>
              <li>On-site restaurant</li>
            </ul>
          </div>
          <div>
            <h2 className="text-xl font-semibold mb-4">Location</h2>
            <ul className="list-disc pl-6 text-gray-600 space-y-2">
              <li>Edge of Magalla Reservoir</li>
              <li>40 minutes from Kurunegala</li>
              <li>120km from Colombo</li>
            </ul>
          </div>
        </div>
      </section>

      {/* Event Spaces */}
      <section className="py-12 px-4 max-w-4xl mx-auto">
        <h2 className="text-2xl font-semibold mb-6 text-center">Event Facilities</h2>
        <div className="grid md:grid-cols-3 gap-6">
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="font-semibold mb-2">Kalugedara Hall</h3>
            <p className="text-gray-600 text-sm mb-2">Capacity: 350</p>
            <p className="text-gray-600 text-sm">Air-conditioned with modern furniture</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="font-semibold mb-2">Pavan Rella Hall</h3>
            <p className="text-gray-600 text-sm mb-2">Capacity: 500</p>
            <p className="text-gray-600 text-sm">Mist fans and spacious layout</p>
          </div>
          <div className="bg-white p-4 rounded-lg shadow-sm">
            <h3 className="font-semibold mb-2">Thaksalawa Hall</h3>
            <p className="text-gray-600 text-sm mb-2">Capacity: 50</p>
            <p className="text-gray-600 text-sm">Intimate air-conditioned space</p>
          </div>
        </div>
      </section>

      {/* Closing Statement */}
      <section className="py-12 px-4 bg-gray-800 text-gray-100">
        <div className="max-w-4xl mx-auto text-center">
          <p className="text-lg">
            At Hotel Buddi Samudra, we remain committed to providing 
            a peaceful retreat that honors both our natural setting 
            and modern hospitality standards.
          </p>
        </div>
      </section>
    </motion.div>
  );
};

export default AboutUs;