import React, { useState } from "react";
import { motion } from "framer-motion";
import hotelfamilyroom1 from "../assets/hotelfamilyroom1.jpg";
import hotelfamilyroom2 from "../assets/hotelfamilyroom2.jpg";
import hotelfamilyroom3 from "../assets/hotelfamilyroom3.jpg";

import hoteldoubleroom1 from "../assets/hoteldoubleroom1.jpg";
import hoteldoubleroom2 from "../assets/hoteldoubleroom2.jpg";
import hoteldoubleroom3 from "../assets/hoteldoubleroom3.jpg";

import hotelsingleroom2 from "../assets/hotelsingleroom2.jpg";
import hotelsingleroom1 from "../assets/hotelsingleroom1.jpg";

const RoomCard = ({ room }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const nextImage = () => {
    setCurrentImage((prev) => (prev + 1) % room.images.length);
  };

  const prevImage = () => {
    setCurrentImage(
      (prev) => (prev - 1 + room.images.length) % room.images.length,
    );
  };

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true }}
      whileHover={{ y: -5 }}
      transition={{ duration: 0.4 }}
      className="bg-white rounded-xl overflow-hidden shadow-lg hover:shadow-xl transition-shadow"
    >
      {/* Image Carousel */}
      <div className="relative h-64 overflow-hidden">
        <img
          src={room.images[currentImage]}
          alt={`${room.title} View ${currentImage + 1}`}
          className="w-full h-full object-cover"
        />

        {/* Navigation Arrows */}
        <button
          onClick={prevImage}
          className="absolute left-2 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full transition-colors"
        >
          ←
        </button>
        <button
          onClick={nextImage}
          className="absolute right-2 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full transition-colors"
        >
          →
        </button>

        {/* Image Indicators */}
        <div className="absolute bottom-2 left-1/2 -translate-x-1/2 flex gap-1">
          {room.images.map((_, index) => (
            <div
              key={index}
              className={`w-1.5 h-1.5 rounded-full transition-colors ${
                index === currentImage ? "bg-white" : "bg-white/50"
              }`}
            />
          ))}
        </div>
      </div>

      {/* Content */}
      <div className="p-6">
        <div className="flex justify-between items-start mb-4">
          <h3 className="text-xl font-medium text-gray-900">{room.title}</h3>
          <span className="text-lg font-medium text-gray-900">
            {room.price}
          </span>
        </div>

        <div className="flex gap-4 mb-4">
          {room.amenities.map((amenity, index) => (
            <div
              key={index}
              className="flex items-center text-sm text-gray-500"
            >
              <span>{amenity}</span>
            </div>
          ))}
        </div>

        <p className="text-gray-600 mb-6">{room.description}</p>

        <motion.button
          whileHover={{ scale: 1.02 }}
          whileTap={{ scale: 0.98 }}
          className="w-full py-3 bg-gray-900 text-white rounded-lg hover:bg-gray-800 transition-colors"
        >
          View Details
        </motion.button>
      </div>
    </motion.div>
  );
};

const RoomShowcase = () => {
  const rooms = [
    {
      title: "Single Room",
      price: "LKR 3,500 + 10% Service Charge",
      description:
        "Perfect for solo travelers, our single rooms offer comfort and convenience with modern amenities and a cozy atmosphere.",
      amenities: [],
      images: [hotelsingleroom2, hotelsingleroom1],
    },
    {
      title: "Double Room",
      price: "LKR 4,265 + 10% Service Charge",
      description:
        "Spacious and elegant, our double rooms feature premium furnishings and a private balcony perfect for couples or business travelers.",
      amenities: [],
      images: [hoteldoubleroom1, hoteldoubleroom3, hoteldoubleroom2],
    },
    {
      title: "Family Suite",
      price: "LKR 7,600 + 10% Service Charge",
      description:
        "Experience ultimate comfort in our family suites with separate living areas, multiple bedrooms, and premium entertainment options.",
      amenities: [],
      images: [hotelfamilyroom1, hotelfamilyroom2, hotelfamilyroom3],
    },
  ];

  return (
    <section className="py-24 px-8 bg-gray-50">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-medium text-gray-900 mb-4">Our Rooms</h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Choose from our selection of thoughtfully designed rooms and suites,
            each offering unique features and stunning views.
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {rooms.map((room, index) => (
            <RoomCard key={index} room={room} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default RoomShowcase;
