import React from "react";
import { motion } from "framer-motion";
import hotel11 from "../assets/hotel11.jpg";

const ParallaxDivider = () => {
  return (
    <section className="relative h-[40vh] min-h-[400px] w-full overflow-hidden">
      {/* Background Image */}
      <div
        className="absolute inset-0 w-full h-full"
        style={{
          backgroundImage: `url(${hotel11})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundAttachment: "fixed",
        }}
      />

      {/* Dark Overlay */}
      <div className="absolute inset-0 bg-gradient-to-b from-black/70 to-black/40" />

      {/* Content */}
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        whileInView={{ opacity: 1, y: 0 }}
        viewport={{ once: true }}
        transition={{ duration: 0.8 }}
        className="relative h-full flex flex-col items-center justify-center text-center px-4 max-w-4xl mx-auto"
      >
        <motion.h2
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.2 }}
          className="text-3xl md:text-4xl font-medium text-white mb-4"
        >
          Create Unforgettable Memories
        </motion.h2>

        <motion.p
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8, delay: 0.4 }}
          className="text-lg text-gray-200 max-w-2xl"
        >
          Every moment at Hotel Buddima Samudra is crafted to deliver an
          experience that exceeds your expectations and creates lasting
          memories.
        </motion.p>
      </motion.div>
    </section>
  );
};

export default ParallaxDivider;
