import HeroCarousel from "../components/hero";
import MinimalTaglineSection from "../components/tag";
import AboutSection from "../components/about";
import RoomShowcase from "../components/rooms";
import GallerySection from "../components/gallery";
import ParallaxDivider from "../components/div";
import Footer from "../components/footer";
import NavBar from "../components/nav";

function Home() {
  return (
    <div>
      <NavBar />
      <HeroCarousel />
      <MinimalTaglineSection />
      <AboutSection />
      <RoomShowcase />
      <ParallaxDivider />
      <GallerySection />
      <Footer />
    </div>
  );
}

export default Home;
