import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import hotel1 from "../assets/hotel1.jpg";
import hotel2 from "../assets/hotel2.jpg";
import hotel3 from "../assets/hotel3.jpg";
import hotel4 from "../assets/hotel4.jpg";
import hotel5 from "../assets/hotel5.jpg";
import hotel6 from "../assets/hotel6.jpg";

const GallerySection = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    {
      src: hotel1,
      alt: "Gallery Image 1",
      caption: "Buddima Samudra Gallery 1",
    },
    {
      src: hotel2,
      alt: "Gallery Image 2",
      caption: "Buddima Samudra Gallery 2",
    },
    {
      src: hotel3,
      alt: "Gallery Image 3",
      caption: "Buddima Samudra Gallery 3",
    },
    {
      src: hotel4,
      alt: "Gallery Image 4",
      caption: "Buddima Samudra Gallery 4",
    },
    {
      src: hotel5,
      alt: "Gallery Image 5",
      caption: "Buddima Samudra Gallery 5",
    },
    {
      src: hotel6,
      alt: "Gallery Image 6",
      caption: "Buddima Samudra Gallery 6",
    },
  ];

  return (
    <section className="py-24 px-8 bg-white">
      <div className="max-w-7xl mx-auto">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          className="text-center mb-16"
        >
          <h2 className="text-3xl font-medium text-gray-900 mb-4">
            Explore Our Hotel
          </h2>
          <p className="text-gray-600 max-w-2xl mx-auto">
            Take a visual journey through our luxurious facilities and stunning
            views
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {images.map((image, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ delay: index * 0.1 }}
              className="relative group cursor-pointer"
              onClick={() => setSelectedImage(image)}
            >
              <div className="aspect-[4/3] overflow-hidden rounded-lg">
                <motion.img
                  src={image.src}
                  alt={image.alt}
                  className="w-full h-full object-cover"
                  whileHover={{ scale: 1.05 }}
                  transition={{ duration: 0.4 }}
                />
              </div>
              <motion.div
                initial={{ opacity: 0 }}
                whileHover={{ opacity: 1 }}
                className="absolute inset-0 bg-black/40 flex items-end p-6 rounded-lg"
              >
                <p className="text-white font-medium">{image.caption}</p>
              </motion.div>
            </motion.div>
          ))}
        </div>
      </div>

      {/* Lightbox Modal */}
      <AnimatePresence>
        {selectedImage && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            onClick={() => setSelectedImage(null)}
            className="fixed inset-0 bg-black/90 z-50 flex items-center justify-center p-8"
          >
            <motion.div
              initial={{ scale: 0.9 }}
              animate={{ scale: 1 }}
              exit={{ scale: 0.9 }}
              className="relative max-w-5xl w-full"
            >
              <img
                src={selectedImage.src}
                alt={selectedImage.alt}
                className="w-full h-auto rounded-lg"
              />
              <button
                onClick={() => setSelectedImage(null)}
                className="absolute top-4 right-4 text-white bg-black/50 rounded-full p-2 hover:bg-black/70 transition-colors"
              >
                ×
              </button>
              <p className="text-white text-center mt-4 text-lg">
                {selectedImage.caption}
              </p>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </section>
  );
};

export default GallerySection;
